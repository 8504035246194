import { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import bgImage from "assets/img/sidebar-uprs_small_no_bg.png";

//import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://uprs.go.ug">
        UPRS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    //backgroundColor:
    //  theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundColor: '#eadbc8', //'#e6e6e6'
    backgroundSize: 'small',
    backgroundPosition: 'center',
    //paddingLeft: '150px'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  empty: {
    backgroundColor: '#eadbc8', //'#e6e6e6'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetSuccess() {
  const classes = useStyles();

  const navigate = useHistory();
  const BackToLogin = () => {
    navigate.push("/login");
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={1} md={2} className={classes.empty}/>
      <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
            <Avatar className={classes.avatar} style={{"backgroundColor":"green"}}>
                <CheckBoxIcon />
            </Avatar>
            <Typography component="h3" variant="h5">
                Your password has been reset successfully.
            </Typography>

            <form className={classes.form}>
                <Button
                    onClick={BackToLogin}
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                Back to Login
                </Button>
            </form>

            <Box mt={5}>
              <Copyright />
            </Box>
        </div>
      </Grid>
      <Grid item xs={false} sm={5} md={6} className={classes.image}/>
    </Grid>
  );
}


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useAxios from "../../utils/useAxios";
import countryArr from "utils/Countries";
import banksArray from "utils/Banks";
import roles from "utils/Roles"

import InputLabel from "@material-ui/core/InputLabel";
// core components
import Input from "@material-ui/core/Input";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import DeedAttachment from './DeedOfAssignment';

import { useState } from "react";
import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../../utils/useAxios';

import CardFooter from "components/Card/CardFooter.js";
import IDReactDropzone from "components/Dropzone/IDReactDropzone.js";
import PPReactDropzone from "components/Dropzone/PPReactDropzone.js";

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = {
  cardCategoryWhite: {
    //color: "rgba(255,255,255,.62)",
    color: "#FFFFFF",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    paddingBottom: "7px",
    textDecoration: "none",
    marginTop: "15px",
    borderBottom: "1px solid #c7c7c7"
  },

  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },

};
const useStyles = makeStyles(styles);

const useCardStyles = makeStyles(theme => ({
  mainCard: {
    backgroundColor: "#EEEEEE",
    borderRadius: "3px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px"},

  infoCard: {
    boxShadow: "0 2px 2px 0 #b0aeae;",
    borderRadius: "2px",
  }
}));

const useSelectStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    minWidth: "100%",
  }}));

const useDateStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: "0",
    margin: "43px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    minWidth: "100%",
  }}));

const useBoxStyles = makeStyles(theme => ({
  formControl: {
    marginTop: "15px",
    marginLeft: "2px"
  },
}));


function dateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

function getStartingDOB(){
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18); //18 years ago for age of consent
  //return date.toISOString().slice(0,10)
  return dateToYMD(date)
}

export default function UserProfile(props) {
  const classes = useStyles();
  const box_classes = useBoxStyles();
  const card_classes = useCardStyles();
  const select_classes = useSelectStyles();
  const date_classes = useDateStyles();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [dobDate, setDob] = useState(getStartingDOB());
  const [fullName, setFullName] = useState('');
  const [alias, setAlias] = useState('');
  //const [dob, setDob] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('Uganda');
  const [district, setLocation] = useState('');
  const [gender, setGender] = useState('');
  const [nin, setNin] = useState('');
  const [role, setRole] = useState([]);
  const [bankName, setBankName] = useState('');
  const [bankBranch, setBankBranch] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [registeredName, setRegisteredName] = useState('');
  const [mobileMoneyNumber, setMobileMoneyNumber] = useState('');
  const [facebookHandle, setFacebookHandle] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [instagramHandle, setInstagramHandle] = useState('');
  const [nextOfKinName, setKinName] = useState('');
  const [nextOfKinContact, setKinContact] = useState('');
  const [nextOfKinRelationship, setKinRelationship] = useState('');
  const [deedAgreementIsChecked, setDeedAgreementIsChecked] = useState(false)

  const [nationalId, setNationalId] = useState([]);
  const [passportPhoto, setPassportPhoto] = useState([]);
  let history = useHistory();

  /* ERROR HANDLERS */
  const [nameError, setNameError] = useState(false)
  const [nameHelperText, setNameHelperText] = useState('')

  const [aliasError, setAliasError] = useState(false)
  const [aliasHelperText, setAliasHelperText] = useState('')

  const [dobError, setDobError] = useState(false)
  const [dobHelperText, setDobHelperText] = useState('')

  const [countryError, setCountryError] = useState(false)
  const [countryHelperText, setCountryHelperText] = useState('')

  const [districtError, setDistrictError] = useState(false)
  const [districtHelperText, setDistrictHelperText] = useState('')

  const [genderError, setGenderError] = useState(false)
  const [genderHelperText, setGenderHelperText] = useState('')

  const [ninError, setNinError] = useState(false)
  const [ninHelperText, setNinHelperText] = useState('')

  const [roleError, setRoleError] = useState(false)
  const [roleHelperText, setRoleHelperText] = useState('')

  const [MMNumberError, setMMNumberError] = useState(false)
  const [MMNumberHelperText, setMMNumberHelperText] = useState('')

  const [registeredNameError, setRegisteredNameError] = useState(false)
  const [registeredNameHelperText, setRegisteredNameHelperText] = useState('')

  const [bankNameError, setBankNameError] = useState(false)
  const [bankNameHelperText, setBankNameHelperText] = useState('')

  const [bankBranchError, setBankBranchError] = useState(false)
  const [bankBranchHelperText, setBankBranchHelperText] = useState('')

  const [bankAccountError, setBankAccountError] = useState(false)
  const [bankAccountHelperText, setBankAccountHelperText] = useState('')

  const [NOKContactError, setNOKContactError] = useState(false)
  const [NOKContactHelperText, setNOKContactHelperText] = useState('')

  const [nationalIDError, setNationalIDError] = useState(false)
  const [nationalIDBoxColor, setNationalIDBoxColor] = useState('')

  const [passportPhotoError, setPassportPhotoError] = useState(false)
  const [passportPhotoBoxColor, setPassportPhotoBoxColor] = useState('')

  const fieldsErrorMap = {'full_name': [setNameError, setNameHelperText],
                        'stage_name':[setAliasError, setAliasHelperText],
                        'dob': [setDobError, setDobHelperText],
                        'gender': [setGenderError, setGenderHelperText],
                        'country': [setCountryError, setCountryHelperText],
                        'district': [setDistrictError, setDistrictHelperText],
                        'nin':[setNinError, setNinHelperText],
                        'role': [setRoleError, setRoleHelperText],
                        'mobile_money_number': [setMMNumberError, setMMNumberHelperText],
                        'registered_name':[setRegisteredNameError, setRegisteredNameHelperText],
                        'bank_name': [setBankNameError, setBankNameHelperText],
                        'bank_branch':[setBankBranchError, setBankBranchHelperText],
                        'account_number': [setBankAccountError, setBankAccountHelperText],
                        'next_of_kin_contact': [setNOKContactError, setNOKContactHelperText],
                        'national_id': [setNationalIDError, setNationalIDBoxColor],
                        'passport_photo': [setPassportPhotoError, setPassportPhotoBoxColor]
  }

  const attachments_fields = ['national_id', 'passport_photo']
  const handleErrors = (errorData) => {
    Object.keys(errorData).forEach(field => {
      const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
      errorSetter(true)
      if(attachments_fields.includes(field) ){
        helperTextSetter('red')
      }
      else{
        console.log(errorData[field][0].message)
        helperTextSetter(errorData[field][0].message)
      }
    });
  }

  const removeError = (field) => {
    const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
    errorSetter(false)
    if(attachments_fields.includes(field) ){
      helperTextSetter('')
    }
  }
  /* END ERROR HANDLERS */

  const api = useAxios();
  const submitHandler = async event => {
    event.preventDefault();
    setLoading(true)
    await api.post(`${BASE_URL}/updateIndividualProfile/`, {
      full_name: fullName,
      stage_name: alias,
      dob: dobDate,
      phone: phone,
      country: country,
      district: district,
      gender: gender,
      nin: nin,
      role: role,
      bank_name: bankName,
      bank_branch: bankBranch,
      account_name: accountName,
      account_number: accountNumber,
      registered_name: registeredName,
      mobile_money_number: mobileMoneyNumber,
      facebook_handle: facebookHandle,
      twitter_handle: twitterHandle,
      instagram_handle: instagramHandle,
      next_of_kin_name: nextOfKinName,
      next_of_kin_contact: nextOfKinContact,
      next_of_kin_rship: nextOfKinRelationship,
      agree: deedAgreementIsChecked,
      national_id: nationalId,
      passport_photo: passportPhoto,
      passport_page: ""},
      ).then( response => {
        //history.push("/home/profile", { message: response.data.detail })
        window.location.replace("/home/profile")
        setLoading(false)
      }).catch( error => {
        setLoading(false)
        handleErrors(JSON.parse(error.response.data))
      })
  }

  const handleDateChange = (date) => {
    const dob = date._d;
    setDob(dateToYMD(dob))
  }

  const handleChangeMultiple = (event) => {
    const values = event.target.value;
    let new_list = []
    for (let i=0, l=values.length; i < l; i += 1){
      if(values[i]){
        new_list.push(values[i]);
      }
    }
    setRole(new_list);
  };

  const handleCheckBoxChange = (e) => {
    setDeedAgreementIsChecked(!deedAgreementIsChecked)
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Paper elevation={2} style={{"paddingBottom": "10px", "paddingTop": "5px", "borderRadius": "3px"}}>
            <CardHeader color="customBlue">
              <p className={classes.cardTitleWhite}>Please Complete and Submit Your Profile</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Full Name"
                    id="full_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    error={nameError}
                    helperText={nameHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput className={classes.formControl}
                    labelText="Alias"
                    id="stage_name"
                    value={alias}
                    onChange={e => setAlias(e.target.value)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    error={aliasError}
                    helperText={aliasHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="gender_label">Gender</InputLabel>
                    <Select
                        labelId="gender_label"
                        id="gender"
                        label="Gender"
                        value={gender}
                        onChange={e => setGender(e.target.value)}
                        error={genderError}
                        onFocus={e => removeError(e.target.id)}
                      >
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker 
                      className={select_classes.formControl} 
                      value={dobDate} 
                      format="yyyy-MMMM-DD"
                      disableFuture
                      label="Date of Birth"
                      onChange={e => handleDateChange(e)} />
                  </MuiPickersUtilsProvider>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="country_label">Country</InputLabel>
                    <Select
                          labelId="country_label"
                          id="country"
                          label="Country"
                          value={country}
                          onChange={e => setCountry(e.target.value)}
                          error={countryError}
                          onFocus={e => removeError(e.target.id)}
                        >
                        {countryArr.map(({ label, value }) => {
                          return <MenuItem value={label} key={value}>{label}</MenuItem>
                          })
                        }
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="District / Area / Town"
                    id="district"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={district}
                    onChange={e => setLocation(e.target.value)}
                    error={districtError}
                    helperText={districtHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                {/* Added Passport No. to the label */}
                  <CustomInput
                    labelText="National ID or Passport No." 
                    id="national_id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={nin}
                    onChange={e => setNin(e.target.value)}
                    error={ninError}
                    helperText={ninHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="role_label">Role</InputLabel>
                    <Select
                        labelId="role_label"
                        id="role"
                        label="Role"
                        value={role}
                        multiple
                        onChange={handleChangeMultiple}
                        error={roleError}
                        onFocus={e => removeError(e.target.id)}
                      >

                      {roles.map((role, key) => (
                        <MenuItem value={role} key={key}>{role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="Twitter Handle"
                      id="twitter"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={twitterHandle}
                      onChange={e => setTwitterHandle(e.target.value)}
                  />
                  </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Facebook Handle"
                    id="facebook"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={facebookHandle}
                    onChange={e => setFacebookHandle(e.target.value)}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Instagram Handle"
                    id="instagram"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={instagramHandle}
                    onChange={e => setInstagramHandle(e.target.value)}
                  />
                </GridItem>

              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Next of Kin [Name]"
                    id="next_of_kin_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={nextOfKinName}
                    onChange={e => setKinName(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Next of Kin [Contact]"
                    id="next_of_kin_contact"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={nextOfKinContact}
                    onChange={e => setKinContact(e.target.value)}
                    error={NOKContactError}
                    helperText={NOKContactHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Next of Kin [Relationship]"
                    id="next_of_kin_rship"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={nextOfKinRelationship}
                    onChange={e => setKinRelationship(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="bank_name_label">Bank Name (optional)</InputLabel>
                    <Select
                        labelId="bank_name_label"
                        id="bank_name"
                        label="Bank Name (optional)"
                        value={bankName}
                        onChange={e => setBankName(e.target.value)}
                        error={bankNameError}
                        onFocus={e => removeError(e.target.id)}
                      >
                      {banksArray.map((bank, key) => {
                         return <MenuItem value={bank} key={key}>{bank}</MenuItem>
                      })}
                    </Select>                    
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Branch Name (optional)"
                    id="bank_branch"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={bankBranch}
                    onChange={e => setBankBranch(e.target.value)}
                    error={bankBranchError}
                    helperText={bankBranchHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Account Name (optional)"
                    id="account_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={accountName}
                    onChange={e => setAccountName(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Account Number (optional)"
                    id="account_number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={accountNumber}
                    onChange={e => setAccountNumber(e.target.value)}
                    error={bankAccountError}
                    helperText={bankAccountHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Mobile Money Number (optional)"
                    id="mobile_money_number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={mobileMoneyNumber}
                    onChange={e => setMobileMoneyNumber(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Registered Name (optional)"
                    id="registered_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={registeredName}
                    onChange={e => setRegisteredName(e.target.value)}
                    error={registeredNameError}
                    helperText={registeredNameHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Box className={box_classes.formControl}>
                      <Typography component="p" style={{"margin": "30px 10px 1px", "font-size":"14px"}} color="error">
                        * Please read the Deed of Assignment and agree to proceed
                      </Typography>
                      <Checkbox 
                        checked={deedAgreementIsChecked}
                        id="agree"
                        onChange={e => handleCheckBoxChange(e)}
                      />
                      I Agree to this &nbsp;<DeedAttachment></DeedAttachment>
                  </Box>
                </GridItem>
              </GridContainer>

              {loading && (
                  <GridContainer>
                    <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
                      <CircularProgress size={32} color="primary"/>
                    </Backdrop>
                  </GridContainer>)}

              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <Typography component="p" style={{"margin": "20px 10px"}}>
                    Attachments
                  </Typography>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <IDReactDropzone 
                    setNationalId={setNationalId} 
                    onChange={e => setNationalId(e.target.files[0])}
                    boxColor={nationalIDBoxColor}
                    setNationalIDBoxColor={setNationalIDBoxColor}>
                  </IDReactDropzone>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <PPReactDropzone 
                    setPassportPhoto={setPassportPhoto} 
                    onChange={e => setPassportPhoto(e.target.files[0])}
                    boxColor={passportPhotoBoxColor}
                    setPassportPhotoBoxColor={setPassportPhotoBoxColor}>

                  </PPReactDropzone>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={submitHandler} color="info">Submit Profile</Button>
            </CardFooter>
          </Paper>
        </GridItem>
      </GridContainer>
    </div>
  );
}

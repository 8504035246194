import React from 'react'
import {useDropzone} from 'react-dropzone'
import { Box, List, ListItem, Typography } from '@material-ui/core'
import {useDzStyles, convertFileToBase } from './DropzoneArea'


const MyDropzone = (props) => {
  const { setIncorporationCertificate, setIncorporationBoxColor, boxColor } = props
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  let attachment = acceptedFiles[0];
  convertFileToBase(attachment, response => {
      let values = []
      values.push({
          file: response,
          title: attachment.name,
          size: parseFloat((parseFloat(attachment.size) / 1024).toFixed(2)),
          details: 'Incorporation Certificate',
          type: attachment.type
      })
      setIncorporationCertificate(values);
  })

  const files = acceptedFiles.map(file => (
      <ListItem key={file.path}>
      {file.path}
      </ListItem>
  ));

  const dz_classes = useDzStyles();
  return (
      <Box className={dz_classes.formControl} color={boxColor} onClick={e => setIncorporationBoxColor('')}>
          <Box {...getRootProps()}>
              <input className={dz_classes.formControl} {...getInputProps()} required/>
              <Typography>Attach Certificate of Incorporation (click to select file)</Typography>
          </Box>
          <aside>
              <List>{files}</List>
          </aside>
      </Box>
  );
};

export default MyDropzone;

import { useState } from 'react';
import axios from "axios";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import bgImage from "assets/img/sidebar-uprs_small_no_bg.png";

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import { Redirect } from "react-router-dom";
import { BASE_URL } from '../../utils/useAxios';

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://uprs.go.ug">
        UPRS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    //backgroundColor:
    //  theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundColor: '#eadbc8', //'#e6e6e6'
    backgroundSize: 'small',
    backgroundPosition: 'center',
    //paddingLeft: '150px'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  empty: {
    backgroundColor: '#eadbc8', //'#e6e6e6'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
}));

export default function SetNewPassword() {
  const classes = useStyles();
  
  const queryParams = new URLSearchParams(window.location.search)
  const reset_token = queryParams.get("token")

  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');

  const [passwordError, setPasswordError] = useState(false)
  const [passwordHelperText, setPasswordHelperText] = useState('')

  const [passwordConfirmError, setPasswordConfirmError] = useState(false)
  const [passwordConfirmHelperText, setPasswordConfirmHelperText] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [redirect, setRedirect] = useState(false)
  const [redirectPath, setRedirectPath ] = useState('')

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [tc, setTC] = useState(false);
  const [message, setMessage] = useState('Error message')
  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const fieldsErrorMap = {'password': [setPasswordError, setPasswordHelperText],
                        'confirm_password': [setPasswordConfirmError, setPasswordConfirmHelperText]}

  function handleResetErrors(error){
    try {
      const errorData = JSON.parse(error.response.data)
      Object.keys(errorData).forEach(field => {
        const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
        errorSetter(true)
        helperTextSetter(errorData[field][0].message)
      });
    } 
    catch (e) {
      //const errorMsg = error.response.data.detail
      const errorMsg = error.response.data.password[0]
      setMessage(errorMsg)
      showNotification()
    }
  }

  const removeError = (field) => {
    const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
    errorSetter(false)
    helperTextSetter('')
  }

  const submitHandler = event => {
    event.preventDefault();
    setLoading(true)
    axios.post(`${BASE_URL}/auth/password_reset/confirm/`,
      {
        password: password,
        token: reset_token,
      },
      {
      headers: {
      }}).then( response => {
        console.log(response.data)
        if (response.data.status == 'OK'){
            setRedirectPath("/reset_successful")
            setRedirect(true)
        }
        setLoading(false)
      }).catch( error => {
        //console.log(error.response.data.password[0])
        setLoading(false)
        handleResetErrors(error)
      })
    }

  if (redirect){
    return <Redirect to={redirectPath} />
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={1} md={2} className={classes.empty}>
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message={message}
          open={tc}
          closeNotification={() => setTC(false)}
          close
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Confirm New Password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                autoFocus
                onChange={e => setPassword(e.target.value)}
                error={passwordError}
                helperText={passwordHelperText}
                onInput={e => removeError(e.target.id)}
                InputProps={{ endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                }}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirm_password"
                label="Confirm New Password"
                type={showConfirmPassword ? "text" : "password"}
                id="confirm_password"
                autoComplete="confirm-password"
                autoFocus
                onChange={e => setConfirmPassword(e.target.value)}
                error={passwordConfirmError}
                helperText={passwordConfirmHelperText}
                onInput={e => removeError(e.target.id)}
                InputProps={{ endAdornment:
                    <InputAdornment position="end">
                    <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                    >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </InputAdornment>
                }}
            />
            <Button
                onClick={submitHandler}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                >
              Reset
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          {loading && (
            <Grid container>
              <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
                <CircularProgress size={32} color="danger"/>
              </Backdrop>
            </Grid>)}
        </div>
      </Grid>
      <Grid item xs={false} sm={5} md={6} className={classes.image}/>
    </Grid>
  );
}

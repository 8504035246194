import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./UserReducer";
import worksReducer from "./WorksReducer";
import entityReducer from "./EntityReducer";
import tabsReducer from "./tabsReducer";

const rootReducer = combineReducers({
    userProfile: userReducer,
    entityProfile: entityReducer,
    works: worksReducer,
    tabs: tabsReducer
})
export default rootReducer;
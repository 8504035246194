import { useState } from 'react';
import { Box } from '@material-ui/core';

import { alpha, makeStyles } from '@material-ui/core/styles';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { MEDIA_BASE_URL } from '../../utils/useAxios';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const useStyles = makeStyles(theme => ({
    iconView: {
      //backgroundColor: alpha(blue['500'], 0.1),
      //color: blue['500'],
      //backgroundColor: alpha(theme.palette.warning.main, 0.25),
      color: '#5D4037',
      padding: 8,
      borderRadius: 4,
      '& .MuiSvgIcon-root': {
        display: 'block',
      },
      '&.web': {
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
        color: theme.palette.warning.main,
      },
      '&.phone': {
        backgroundColor: alpha(theme.palette.success.main, 0.15),
        color: theme.palette.success.dark,
      },
    },
    wordAddress: {
      wordBreak: 'break-all',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: '1px',
      marginTop: '1px'
    },
    spacer:{
      marginBottom: '18px'
    }
}));


function CustomizedDialog(props) {
    const {open, setOpen, handleClickOpen, handleClose, url, modalTitle} = props
    return(
      <div>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} style={{ minWidth: "550px", minHeight: "550px"}}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography fontSize={14}>
                    {modalTitle}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <iframe src={`${MEDIA_BASE_URL}${url}`} style={{ minWidth: "550px", minHeight: "550px" }}>

                </iframe>
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
                <Typography fontSize={14}>
                    Close
                </Typography>
            </Button>
            </DialogActions>
        </Dialog>
      </div>
    );
}

function Deed(props){
    const classes = useStyles();
    const {showDeedDialog, setUrl, setModalTitle} = props
    const deedUrl = ("/media/uprs_html.htm")
    const newTitle = 'Deed of Assignment'
    return(
        <Box component="a" href="#" onClick={e => showDeedDialog(e, setUrl, newTitle, setModalTitle)}>
            Deed Of Assignment
        </Box>
    )
}


const DeedAttachment = () => {
    const [open, setOpen] = useState(false)
    const [url, setUrl] = useState("/media/uprs_html.htm")
    const [modalTitle, setModalTitle] = useState('Deed of Assignment')
    const handleClickOpen = () => {
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    };

    const showDeedDialog = (event, setUrl, newTitle, setModalTitle) => {
        setUrl(url)
        setModalTitle(newTitle)
        handleClickOpen()
    }
    return(
        <>
            <Deed showDeedDialog={showDeedDialog} setUrl={setUrl} setModalTitle={setModalTitle}/>
            <CustomizedDialog open={open} setOpen={setOpen} handleClickOpen={handleClickOpen} handleClose={handleClose} url={url} modalTitle={modalTitle}/>
        </>
    )
}

export default DeedAttachment
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';

import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import MarkEmailReadIcon from '@material-ui/icons/Mail';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import bgImage from "assets/img/sidebar-uprs_small_no_bg.png";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://uprs.go.ug">
        UPRS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    //backgroundColor:
    //  theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundColor: '#eadbc8', //'#e6e6e6'
    backgroundSize: 'small',
    backgroundPosition: 'center',
    //paddingLeft: '150px'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  empty: {
    backgroundColor: '#eadbc8', //'#e6e6e6'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetSuccess() {
  const classes = useStyles();


  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={1} md={2} className={classes.empty}/>
      <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
            <Avatar className={classes.avatar} style={{'backgroundColor':'#2196F3'}}>
                <MarkEmailReadIcon />
            </Avatar>
            <Typography component="h5" variant="h5" style={{"fontSize":"18px"}}>
                <p>
                    Hello, if an account exists with the email you have submitted, You will receive instructions on how to reset your password shortly.
                    <br/>
                </p>
                <p>
                    Please check your email
                </p>
                <hr/>

                In case you have not yet received the email; 
                <ul>
                    <li>
                    Please make sure you have used the exact email address you registered with.
                    </li>
                    <li>
                    Remember to check your <b>spam/junk</b> folder.
                    </li>
                </ul>
                    
                <hr/>
                <p>
                    Alternatively, you can contact The UPRS Admin Team for support.
                </p>
            </Typography>

            {/*
            <form className={classes.form}>
                <Button
                    onClick={BackToLogin}
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                Back to Login
                </Button>
            </form>*/}

            <Box mt={5}>
              <Copyright />
            </Box>
        </div>
      </Grid>
      <Grid item xs={false} sm={5} md={6} className={classes.image}/>
    </Grid>
  );
}

import { useState } from 'react';
import axios from "axios";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import bgImage from "assets/img/sidebar-uprs_small_no_bg.png";

import { Redirect } from "react-router-dom";
import { BASE_URL } from '../../utils/useAxios';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://uprs.go.ug">
        UPRS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    //backgroundColor:
    //  theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundColor: '#e6e6e6',
    backgroundSize: 'small',
    backgroundPosition: 'center',
    //paddingLeft: '150px'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  empty: {
    backgroundColor: '#e6e6e6'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
}));

export default function RequestResetPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [redirect, setRedirect] = useState(false)
  const [redirectPath, setRedirectPath ] = useState('')

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const submitHandler = event => {
    event.preventDefault();
    setLoading(true)
    axios.post(`${BASE_URL}/auth/password_reset/`,
      {
        email: email,
      },
      {
      headers: {
      }}).then( response => {
        //console.log(response.data)
        if (response.data.status == 'OK'){
          setRedirectPath("/password_reset_email_sent")
          setRedirect(true)
        }
        setLoading(false)
        setRedirect(true)
      }).catch( error => {
        setLoading(false)
      })
  }

  if (redirect){
    return <Redirect to={redirectPath} />
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={5} md={7} className={classes.image}/>
      <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onChange={e => setEmail(e.target.value)}
              placeholder='Please use the email you registered with'
              autoComplete="email"
              autoFocus
            />
            <Button
              onClick={submitHandler}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Continue
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          {loading && (
            <Grid container>
              <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
                <CircularProgress size={32} color="danger"/>
              </Backdrop>
            </Grid>)}
        </div>
      </Grid>
      <Grid item xs={false} sm={1} md={1} className={classes.empty}/>
    </Grid>
  );
}

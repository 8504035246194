import { useEffect, useState } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/WorksTable";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import useAxios from "../../utils/useAxios";
import { BASE_URL } from '../../utils/useAxios';

import {useHistory, useLocation } from 'react-router-dom';

import actionCreators from '../../redux/actions/actionTypes'
import store from '../../redux/Store'

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);


const WorksList = (props) => {
  let location = useLocation();
  let history = useHistory();
  const classes = useStyles();
  //const [worksData, setWorksData] = useState([])
  const worksData = props.works;
  const [workID, setWorkID] = useState('')

  function handleAddWork(event){
    event.preventDefault()
    history.push("/home/addwork")
  }

  const api = useAxios();
  if(!Object.keys(worksData).length){
    api.get(`${BASE_URL}/myWorks/`).then((response) => {
      store.dispatch({type: actionCreators.works_refreshed(), payload: response.data})
    });
  }


  const successMessage = location.state? location.state.message : ''
  const [successNote, setSuccessNote] = useState(false);

  const showSuccessNotification = (message) => {
    if (message){
      setSuccessNote(true);
      setTimeout(function () {
        setSuccessNote(false);
      }, 4000);}
  };

  useEffect(() => {showSuccessNotification(successMessage)}, [])

  return (
    <>
    {Object.keys(worksData).length && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="customGreen">
              <h4 className={classes.cardTitleWhite}>Songs</h4>
              <p className={classes.cardCategoryWhite}>
                Click on song for details
              </p>
            </CardHeader>
            <CardBody> 
              <Table
                tableHeaderColor="customGreen"
                tableHead={["Title", "Work Number", "Genre", "Date Uploaded"]}
                tableData={worksData.works}
                setWorkID = {setWorkID}
              />
              <Button onClick={(e) => handleAddWork(e)} color="info" style={{'float':'right'}}>Add</Button>
            </CardBody>
          </Card>
        </GridItem>
        {/*
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {workID && (
            <div>
              <Work workID={workID}/>
            </div>)}
          </Card>
        </GridItem>*/}
        <GridItem>
          <Snackbar
              place="tc"
              color="success"
              icon={AddAlert}
              message={successMessage}
              open={successNote}
              closeNotification={() => setSuccessNote(false)}
              close
          />
        </GridItem>
      </GridContainer>
      
      )}
    </>
  );
}

export default WorksList
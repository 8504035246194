const roles = [
    'Author',
    'Lyricist',
    'Arranger',
    'Composer',
    'Performer',
    'Publisher',
    'Producer / Label'
  ];

export default roles
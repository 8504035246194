const genreArray = [{'name': 'Afrobeat'},
            {'name': 'Kadongokamu'},
            {'name': 'LugaFlow'},
            {'name': 'Band'},
            {'name': 'Rhythm and blues (R n B)'},
            {'name': 'Dancehall'},
            {'name': 'Ragga'},
            {'name': 'Hiphop'},
            {'name': 'Reggae'},
            {'name': 'Lingala'},
            {'name': 'Swahiri'},
            {'name': 'Kidandali'},
            {'name': 'Rhumba'},
            {'name': 'Zouk'},
            {'name': 'Soukous'},
            {'name': 'Bigwala'}]

export default genreArray
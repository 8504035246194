import UserProfile from "../views/UserProfile/Display/Profile";
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    //console.log("INITIAL STATE", state.userProfile);
    return {
        profile: state.userProfile
    };
}

const mapDispatchToProps = (dispatch) => ({
    onLoadProfileData: () => dispatch()
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfile)
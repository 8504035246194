import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Header from './Header';
import GridContainer from '../../../components/GridContainer';
import Grid from '@material-ui/core/Grid';

import About from './About';
import Attachments from './Attachments';
import Billing from './Billing';
import Contact from './Contact';

import AboutEntity from './AboutEntity'
import BillingEntity from './BillingEntity';
import AttachmentsEntity from './AttachmentsEntity';
import ContactEntity from './ContactEntity';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useAxios from "../../../utils/useAxios";
import { BASE_URL } from '../../../utils/useAxios';

import { useLocation, Redirect } from "react-router-dom";
import actionCreators from '../../../redux/actions/actionTypes'
import store from '../../../redux/Store'

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";


const useStyles = makeStyles(() => ({
  pageFull: {
    width: '100%',
  },
  profileSidebar: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  profileMainContent: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
}));

const Profile = ({profile}) => {
  var profileData = profile
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('about');

  const api = useAxios();
  if(Object.keys(profileData).length === 0){
    api.get(`${BASE_URL}/myProfile/`).then((response) => {
      store.dispatch({type: actionCreators.profile_refreshed(), payload: response.data.profile})
    });
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let location = useLocation();
  const successMessage = location.state? location.state.message : ''
  const [successNote, setSuccessNote] = useState(false);

  const showSuccessNotification = (message) => {
    if (message){
      setSuccessNote(true);
      setTimeout(function () {
        setSuccessNote(false);
      }, 4000);}
  };
  useEffect(() => {showSuccessNotification(successMessage)}, [])

  if(Object.keys(profileData).length !== 0){
    if(!profileData.submitted){
      return <Redirect to="/home/complete_profile" />
    }

    localStorage.setItem('profileData', JSON.stringify(profileData))
    if(profileData.member_type == 'individual'){
        return (
            <React.Fragment>
            {Object.keys(profileData).length !== 0 && (
                <Box className={classes.pageFull}>
                <Header classes={classes} userDetail={profileData} tabValue={tabValue} handleTabChange={handleTabChange} />
                <GridContainer>
                  <Grid item xs={12} lg={8} className={classes.profileMainContent}>
                    <Box mb={6} marginBottom="22px">
                        <About userDetail={profileData} />
                    </Box>
                    
                    <Box mb={6}>
                        <Attachments userDetail={profileData} />
                    </Box>
                    </Grid>
                  <Grid item xs={12} lg={4} className={classes.profileSidebar}>
                    <Box mb={6} marginBottom="22px">
                        <Contact userDetail={profileData} />
                    </Box>
                    <Box mb={6}>
                        <Billing userDetail={profileData} />
                    </Box>
                  </Grid>
                  <Snackbar
                        place="tc"
                        color="success"
                        icon={AddAlert}
                        message={successMessage}
                        open={successNote}
                        closeNotification={() => setSuccessNote(false)}
                        close
                    />
                </GridContainer>
              </Box>
            )}
            </React.Fragment>
        );
    }
    
    else if(profileData.member_type == 'entity'){
        return (
            <React.Fragment>
            {Object.keys(profileData).length !== 0 && (
                <Box className={classes.pageFull}>
                  <Header classes={classes} userDetail={profileData} tabValue={tabValue} handleTabChange={handleTabChange} />
                  <GridContainer>
                      <Grid item xs={12} lg={8} className={classes.profileMainContent}>
                        <Box mb={6} marginBottom="22px">
                            <AboutEntity userDetail={profileData} />
                        </Box>
                        
                        <Box mb={6}>
                            <AttachmentsEntity userDetail={profileData} />
                        </Box>
                        </Grid>
                        <Grid item xs={12} lg={4} className={classes.profileSidebar}>
                        {/*
                        <Box mb={6} marginBottom="22px">
                            <ContactEntity userDetail={profileData} />
                        </Box>*/}
                        <Box mb={6}>
                            <BillingEntity userDetail={profileData} />
                        </Box>
                      </Grid>

                      <Snackbar
                          place="tc"
                          color="success"
                          icon={AddAlert}
                          message={successMessage}
                          open={successNote}
                          closeNotification={() => setSuccessNote(false)}
                          close
                      />
                  </GridContainer>
                </Box>
            )}
            </React.Fragment>
        );
    }
  }
  else{
    return null
    }
};

export default Profile;

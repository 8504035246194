/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Music from "@material-ui/icons/QueueMusic";
import Person from "@material-ui/icons/Person";
import AccountBalance from '@material-ui/icons/AccountBalance';
import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined";
//import LibraryBooks from "@material-ui/icons/LibraryBooks";
//import BubbleChart from "@material-ui/icons/BubbleChart";
//import LocationOn from "@material-ui/icons/LocationOn";
//import Notifications from "@material-ui/icons/Notifications";
//import Unarchive from "@material-ui/icons/Unarchive";
//import Language from "@material-ui/icons/Language";

// core components/views for Admin layout
//import DashboardPage from "views/Dashboard/Dashboard.js";

//import UserProfile from "views/UserProfile/Display/IndexIndividual";
//import UserProfile from "containers/Individual";
//import CompleteUserProfile from "views/UserProfile/CompleteUserProfile";
//import CompleteEntityProfile from "views/UserProfile/CompleteEntityProfile";

import CompleteProfile from "views/UserProfile/CompleteProfile";
import UserProfile from "containers/ProfileContainer";

//import WorksList from "views/Works/WorksList";
import WorksList from "containers/Works";
import AddWork from "views/Works/AddWork";
import EditWork from "views/Works/EditWork";
import ViewWork from "views/Works/WorkDetail"
import EditProfile from "views/UserProfile/EditProfile"

//import Login from "views/Account/Login"
//import TableList from "views/TableList/TableList.js";

//import Typography from "views/Typography/Typography.js";
//import Icons from "views/Icons/Icons.js";
//import Maps from "views/Maps/Maps.js";
//import NotificationsPage from "views/Notifications/Notifications.js";
//import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
//import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  /*
  {
    path: "/login",
    name: "Login",
    icon: AccountCircleOutlined,
    component: Login,
    layout: "/admin",
  },*/
  {
    path: "/profile",
    name: "Profile",
    icon: Person,
    component: UserProfile,
    layout: "/home",
  },
  {
    path: "/works",
    name: "Works",
    icon: Music,
    component: WorksList,
    layout: "/home",
  },
  {
    path: "/addwork",
    name: "Add Work",
    icon: Music,
    component: AddWork,
    layout: "/home",
  },
  {
    path: "/editwork",
    name: "Edit Work",
    icon: Music,
    component: EditWork,
    layout: "/home",
  },
  {
    path: "/work_details",
    name: "Song Details",
    icon: Music,
    component: ViewWork,
    layout: "/home",
  },
  {
    path: "/edit_profile",
    name: "Edit Profile",
    icon: Person,
    component: EditProfile,
    layout: "/home",
  },
  {
    path: "/complete_profile",
    name: "Complete Profile",
    //icon: Person,
    icon: Person,
    component: CompleteProfile,
    layout: "/home",
  },

  /*
  {
    path: "/complete_entity_profile",
    name: "Entity Profile",
    icon: AccountBalance,
    component: CompleteEntityProfile,
    layout: "/home",
  },

  
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  },
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl",
  },
  {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/admin",
  }, */
];

export default dashboardRoutes;

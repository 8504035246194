import { useState } from "react";
import axios from "axios";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import bgImage from "assets/img/sidebar-uprs_small_no_bg.png";
import {Redirect} from "react-router-dom"
import actionCreators from '../../redux/actions/actionTypes'
import store from '../../redux/Store'

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { BASE_URL } from '../../utils/useAxios';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://uprs.go.ug">
        UPRS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useSelectStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 10px 0",
    position: "relative",
    verticalAlign: "unset",
    minWidth: "100%",
  }}));


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    //backgroundColor:
    //  theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundColor: '#e6e6e6',
    backgroundSize: 'small',
    backgroundPosition: 'center',
    //paddingLeft: '150px'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  empty: {
    backgroundColor: '#e6e6e6'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
}));

export default function Registration() {
    const select_classes = useSelectStyles();
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [membership, setMembership] = useState('')
    const [redirect, setRedirect] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = () => {
      setOpen(false);
    };

    const [tc, setTC] = useState(false);
    const [message, setMessage] = useState('Error message')
    const showNotification = () => {
      setTC(true);
      setTimeout(function () {
        setTC(false);
      }, 6000);
    };

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
    
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };
    
    const handleMouseDownConfirmPassword = (event) => {
      event.preventDefault();
    };

    /* ERROR HANDLERS */
    const [emailError, setEmailError] = useState(false)
    const [emailHelperText, setEmailHelperText] = useState('')

    const [passwordError, setPasswordError] = useState(false)
    const [passwordHelperText, setPasswordHelperText] = useState('')

    const [passwordConfirmError, setPasswordConfirmError] = useState(false)
    const [passwordConfirmHelperText, setPasswordConfirmHelperText] = useState('')

    const [membershipError, setMembershipError] = useState(false)
    const [membershipHelperText, setMembershipHelperText] = useState('')

    const [contactError, setContactError] = useState(false)
    const [contactHelperText, setContactHelperText] = useState('')

    const fieldsErrorMap = {'email': [setEmailError, setEmailHelperText],
                          'contact':[setContactError, setContactHelperText],
                          'password': [setPasswordError, setPasswordHelperText],
                          'confirm_password': [setPasswordConfirmError, setPasswordConfirmHelperText],
                          'membership': [setMembershipError, setMembershipHelperText]}

    function handleRegistrationErrors(error){
      try {
        console.log(error)
        const errorData = JSON.parse(error.response.data)
        Object.keys(errorData).forEach(field => {
          const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
          errorSetter(true)
          helperTextSetter(errorData[field][0].message)
        });
      } 
      catch (e) {
        const errorMsg = error.response.data.detail
        setMessage(errorMsg)
        showNotification()
      }
    }

    const removeError = (field) => {
      const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
      errorSetter(false)
      helperTextSetter('')
    }
    /* END ERROR HANDLERS */
  
    const submitHandler = event => {
      event.preventDefault();
      setLoading(true)
      axios.post(`${BASE_URL}/register/`, {
        //username: username,
        email: email,
        username: email,
        contact: contact,
        membership: membership,
        password: password,
        confirm_password: confirm_password},
        {
          withCredentials:false},
        {
        headers: {
            //'Authorization': `Token ${localStorage.getItem('token')}`
        }}
        ).then( response => {
            if(response.data.message == 'OK'){
              localStorage.setItem("userToken", response.data.token)
              localStorage.setItem("isAuthenticated", true)
              //store.dispatch({type: actionCreators.profile_refreshed(), payload: response.data.profile})
              store.dispatch({type: actionCreators.profile_refreshed(), payload: response.data.profile})
              setLoading(false)
              setRedirect(true)
            }
        }).catch( error => {
            setLoading(false)
            handleRegistrationErrors(error)
        })
    }
    if (redirect){
      return <Redirect to="/home/complete_profile" />
    }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={5} md={7} className={classes.image}/>
      <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <form className={classes.form}>
            <FormControl className={select_classes.formControl}>
              <InputLabel id="membership_label">Please choose your membership</InputLabel>
              <Select
                  labelId="membership_label"
                  id="membership"
                  label="Membership"
                  name="membership"
                  value={membership}
                  onChange={e => setMembership(e.target.value)}
                  error={membershipError}
                  onSelect={e => removeError(e.target.id)}
                >
                <MenuItem value="individual">Individual</MenuItem>
                <MenuItem value="entity">Company / Band / Choir etc</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => setEmail(e.target.value)}
              error={emailError}
              helperText={emailHelperText}
              onInput={e => removeError(e.target.id)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="contact"
              label="Phone Number"
              type="phone"
              id="contact"
              autoComplete="phone-number"
              autoFocus
              onChange={e => setContact(e.target.value)}
              error={contactError}
              helperText={contactHelperText}
              onInput={e => removeError(e.target.id)}
            />
            {/*}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="membership"
              label="Membership"
              name="membership"
              autoComplete="membership"
              autoFocus
              onChange={e => setMembership(e.target.value)}
            /> */}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              autoFocus
              onChange={e => setPassword(e.target.value)}
              error={passwordError}
              helperText={passwordHelperText}
              onInput={e => removeError(e.target.id)}
              InputProps={{ endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              id="confirm_password"
              autoComplete="confirm-password"
              autoFocus
              onChange={e => setConfirmPassword(e.target.value)}
              error={passwordConfirmError}
              helperText={passwordConfirmHelperText}
              onInput={e => removeError(e.target.id)}
              InputProps={{ endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }}
            />
            <Button
              onClick={submitHandler}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Register
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Already have an account? Login here"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          {loading && (
            <Grid container>
              <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
                <CircularProgress size={32} color="danger"/>
              </Backdrop>
            </Grid>)}
        </div>
      </Grid>
      <Grid item xs={false} sm={1} md={1} className={classes.empty}>
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message={message}
          open={tc}
          closeNotification={() => setTC(false)}
          close
        />
      </Grid>
    </Grid>
  );
}

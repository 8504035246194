
import CompleteUserProfile from './CompleteUserProfile'
import CompleteEntityProfile from './CompleteEntityProfile'
import store from '../../redux/Store'

const Profile = () => {
    const membership_type = store.getState().userProfile.member_type
    if (membership_type === 'individual'){
        return <CompleteUserProfile/>
    }
    else if(membership_type === 'entity') 
    {
        return <CompleteEntityProfile/>
    }
    return null
}

export default Profile
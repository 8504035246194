import React from 'react';
import { Box } from '@material-ui/core';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkIcon from '@material-ui/icons/Link';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles(theme => ({
  iconView: {
    //backgroundColor: alpha(blue['500'], 0.1),
    color: blue['500'],
    padding: 8,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
    '&.web': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
    },
    '&.phone': {
      backgroundColor: alpha(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark,
    },
  },
  wordAddress: {
    wordBreak: 'break-all',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '1px',
    marginTop: '1px'
  },
  spacer:{
      marginBottom: '18px'
  }
}));

const Contact = ({ userDetail }) => {
  //const { profile } = userDetail
  const { user, address, twitter_handle, facebook_handle, instagram_handle, phone } = userDetail//profile;
  const { email } = user
  const classes = useStyles();
  return (
    <CmtCard elevation={2}>
      <CmtCardHeader title="Contact" />
      <CmtCardContent>
        <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <MailOutlineIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={13} color="text.primary">
              Email
            </Box>
            <Box component="p" className={classes.wordAddress}>
                {email}
            </Box>
          </Box>
        </Box>

        <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
          {/*<Box className={clsx(classes.iconView, 'phone')}>*/}
          <Box className={classes.iconView}>
            <LocalPhoneIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={13} color="text.primary">
              Phone
            </Box>
            <Box component="p" className={classes.wordAddress} color="text.primary">
              {phone}
            </Box>
          </Box>
        </Box>

        { facebook_handle && (
        <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
          {/*<Box className={clsx(classes.iconView, 'web')}>*/}
          <Box className={classes.iconView}>
            <FacebookIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={13} color="text.primary">
              Facebook
            </Box>
            <Box component="p" className={classes.wordAddress}>
              <Box component="a" href={facebook_handle}>
                {facebook_handle}
              </Box>
            </Box>
          </Box>
        </Box>)}

        { twitter_handle && (
        <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <TwitterIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={13} color="text.primary">
              Twitter
            </Box>
            <Box component="p" className={classes.wordAddress}>
              <Box component="a" href={twitter_handle}>
                {twitter_handle}
              </Box>
            </Box>
          </Box>
        </Box>)}
        { instagram_handle && (
        <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <InstagramIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={13} color="text.primary">
              Instagram
            </Box>
            <Box component="p" className={classes.wordAddress}>
              <Box component="a" href={instagram_handle}>
                {instagram_handle}
              </Box>
            </Box>
          </Box>
        </Box>)}
      </CmtCardContent>
    </CmtCard>
  );
};

export default Contact;

Contact.prototype = {
  userDetail: PropTypes.object.isRequired,
};

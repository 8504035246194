
const actionTypes = {
    login_succeeded: 'LOGIN_SUCCEEDED',
    profile_refreshed: 'PROFILE_REFRESHED',
    works_fetched: 'WORKS_FETCHED',
    works_refreshed: 'WORKS_REFRESHED'
}


const actionCreators = {
    login_succeeded: function(){
        return {type: actionTypes.login_succeeded}
    },
    profile_refreshed: function(){
        return {type: actionTypes.profile_refreshed}
    },
    works_fetched: function(){
        return {type: actionTypes.works_fetched}
    },
    works_refreshed: function(){
        return {type: actionTypes.works_refreshed}
    },
}

export default actionCreators;
import {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Attachments from './WorkAttachments.js';

import Button from "components/CustomButtons/Button.js";
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AudioIcon from '@material-ui/icons/Audiotrack';
import UpdatedIcon from '@material-ui/icons/UpdateOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AlbumIcon from '@material-ui/icons/Album';
import YoutubeIcon from '@material-ui/icons/YouTube';
//import blue from '@material-ui/core/colors/blue';
import useAxios from "../../utils/useAxios";
import { BASE_URL } from '../../utils/useAxios';


const useStyles = makeStyles(theme => ({
  iconView: {
    //backgroundColor: alpha(blue['500'], 0.1),
    //color: blue['500'],
    //color: '#57311b',
    color: '#c9920e',
    padding: 8,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
    '&.web': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
    },
    '&.phone': {
      backgroundColor: alpha(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark,
    },
  },
  iconViewYoutube: {
    color: 'red',
    padding: 8,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
    '&.web': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
    },
    '&.phone': {
      backgroundColor: alpha(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark,
    },
  },
  wordAddress: {
    wordBreak: 'break-all',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '1px',
    marginTop: '1px'
  },
  spacer:{
      marginBottom: '18px'
  }
}));

//const Work = ({workID}) => {
const Work = () => {
    const workID = localStorage.getItem('current_work_id')
    let history = useHistory()
    const api = useAxios();
    const [workData, setWorkData] = useState('')

    function handleEdit(){
        history.push("/home/editwork")
    }

    function handleBack(){
        history.push("/home/works")
    }
   
    useEffect(async () => {
        await api.get(`${BASE_URL}/myWorks/${workID}/`,
        ).then((response) => {
        setWorkData(response.data.work);
        localStorage.setItem('currentWorkData', JSON.stringify(response.data.work))
        })
    }, [workID]);
    
    const classes = useStyles();

    return (
        <>
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
                <Card>
                    <CardHeader color="customBlue" style={{"padding": "5px 15px"}}>
                    <h5 className={classes.cardTitleWhite}>{workData.title}</h5>
                    </CardHeader>
                    <CardBody> 
                        {/*<CmtCardHeader title="Contact" />*/}
                        <CmtCardContent>
                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <Box className={classes.iconView}>
                                    <AudioIcon />
                                </Box>
                                <Box ml={5}>
                                    <Box component="span" fontSize={13} color="text.primary">
                                    Work Number
                                    </Box>
                                    <Box component="p" className={classes.wordAddress}>
                                        {workData.work_num}
                                    </Box>
                                </Box>
                                <Box style={{"marginLeft": "auto"}}>

                                </Box>
                            </Box>

                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                            {/*<Box className={clsx(classes.iconView, 'phone')}>*/}
                            <Box className={classes.iconView}>
                                <UpdatedIcon />
                            </Box>
                            <Box ml={5}>
                                <Box component="span" fontSize={13} color="text.primary">
                                Uploaded
                                </Box>
                                <Box component="p" className={classes.wordAddress} color="text.primary">
                                    {workData.date_uploaded}
                                </Box>
                            </Box>
                            </Box>

                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                            {/*<Box className={clsx(classes.iconView, 'phone')}>*/}
                            <Box className={classes.iconView}>
                                <CalendarTodayIcon />
                            </Box>
                            <Box ml={5}>
                                <Box component="span" fontSize={13} color="text.primary">
                                Released
                                </Box>
                                <Box component="p" className={classes.wordAddress} color="text.primary">
                                    {workData.date_released}
                                </Box>
                            </Box>
                            </Box>
                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                            {/*<Box className={clsx(classes.iconView, 'phone')}>*/}
                            <Box className={classes.iconView}>
                                <AlbumIcon />
                            </Box>
                            <Box ml={5}>
                                <Box component="span" fontSize={13} color="text.primary">
                                Genre and Duration
                                </Box>
                                <Box component="p" className={classes.wordAddress} color="text.primary">
                                    {workData.genre} - {workData.duration} minutes
                                </Box>
                            </Box>
                            </Box>

                            {workData.youtube_link && (
                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <Box className={classes.iconViewYoutube}>
                                    <YoutubeIcon />
                                </Box>
                                <Box ml={5}>
                                    <Box component="span" fontSize={13} color="text.primary">
                                    Youtube Link
                                    </Box>
                                    <Box component="p" className={classes.wordAddress}>
                                    <Box component="a" href={workData.youtube_link}>
                                        {workData.youtube_link}
                                    </Box>
                                    </Box>
                                </Box>
                            </Box>)}

                            {workData.other_link && (
                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <Box className={classes.iconView}>
                                    <LinkIcon />
                                </Box>
                                <Box ml={5}>
                                    <Box component="span" fontSize={13} color="text.primary">
                                    Other Link
                                    </Box>
                                    <Box component="p" className={classes.wordAddress}>
                                    <Box component="a" href={workData.other_link}>
                                        {workData.other_link}
                                    </Box>
                                    </Box>
                                </Box>
                            </Box>)}

                            {workData.producer && (
                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <Box className={classes.iconView}>
                                    <AccountCircleIcon />
                                </Box>
                                <Box ml={5}>
                                    <Box component="span" fontSize={13} color="text.primary">
                                    Producer & Percentage
                                    </Box>
                                    <Box component="p" className={classes.wordAddress} color="text.primary">
                                        {workData.producer} - {workData.producer_percentage}%
                                    </Box>
                                </Box>
                            </Box>)}

                            {workData.composer && (
                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <Box className={classes.iconView}>
                                    <AccountCircleIcon />
                                </Box>
                                <Box ml={5}>
                                    <Box component="span" fontSize={13} color="text.primary">
                                    Composer & Percentage
                                    </Box>
                                    <Box component="p" className={classes.wordAddress} color="text.primary">
                                        {workData.composer} - {workData.composer_percentage}%
                                    </Box>
                                </Box>
                            </Box>)}

                            {workData.performer && (
                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <Box className={classes.iconView}>
                                    <AccountCircleIcon />
                                </Box>
                                <Box ml={5}>
                                    <Box component="span" fontSize={13} color="text.primary">
                                    Performer & Percentage
                                    </Box>
                                    <Box component="p" className={classes.wordAddress} color="text.primary">
                                        {workData.performer} - {workData.performer_percentage}%
                                    </Box>
                                </Box>
                            </Box>)}

                            {workData.publisher && (
                            <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <Box className={classes.iconView}>
                                    <AccountCircleIcon />
                                </Box>
                                <Box ml={5}>
                                    <Box component="span" fontSize={13} color="text.primary">
                                    Publisher & Percentage
                                    </Box>
                                    <Box component="p" className={classes.wordAddress} color="text.primary">
                                        {workData.publisher} - {workData.publisher_percentage}%
                                    </Box>
                                </Box>
                            </Box>)}

                            {(workData.work_agreement || workData.artwork) && (
                            <Box mb={6}>
                                <Attachments workDetail={workData} />
                            </Box>)}
                        </CmtCardContent>
                        <Button color="info" style={{'float':'left'}} onClick={handleBack}>Back</Button>
                        <Button color="danger" style={{'float':'right'}} onClick={handleEdit}>Edit</Button>

                        
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
        </>
    );
};

export default Work;

Work.prototype = {
  workDetail: PropTypes.object.isRequired,
};

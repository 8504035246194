import { makeStyles } from "@material-ui/core/styles";

export const useDzStyles = makeStyles(theme => ({
  formControl: {
    border: "2px dashed rgba(0, 0, 0, 0.06)",
    display: "flex",
    outline: "none",
    padding: "40px 20px",
    transition: "border .24s ease-in-out",
    alignItems: "center",
    borderRadius: "2px",
    flexDirection: "column",
    backgroundColor: "#f4f4f7",
  }
})) 


export const convertFileToBase = (selectedFile, successCallback) => {
  if(selectedFile){
      let fileReader = new FileReader();
      let base64
      fileReader.onload = function(fileLoadedEvt){
          base64 = fileLoadedEvt.target.result
          successCallback(base64)
      }
      fileReader.readAsDataURL(selectedFile)
  }
}


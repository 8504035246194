
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import useAxios from "../../utils/useAxios";
import countryArr from "utils/Countries";
import banksArray from "utils/Banks";
import roles from "utils/Roles"

import InputLabel from "@material-ui/core/InputLabel";
// core components
import Input from "@material-ui/core/Input";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import DeedAttachment from './DeedOfAssignment';

import { useState } from "react";
import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../../utils/useAxios';

import CardFooter from "components/Card/CardFooter.js";
import ResolutionsDropzone from "components/Dropzone/ResolutionsDropzone.js"
import TradingDropzone from "components/Dropzone/TradingLicenseDropzone.js"
import TINDropzone from "components/Dropzone/TINDropzone.js"
import IncorporationDropzone from "components/Dropzone/IncorporationDropzone.js"

//import Dropzone from 'react-dropzone'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
//import DateFnsUtils from '@date-io/date-fns'
import MomentUtils from '@date-io/moment';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  cardCategoryWhite: {
    //color: "rgba(255,255,255,.62)",
    color: "#FFFFFF",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    paddingBottom: "7px",
    textDecoration: "none",
    marginTop: "15px",
    borderBottom: "1px solid #c7c7c7"
  },

  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },

};
const useStyles = makeStyles(styles);

const useCardStyles = makeStyles(theme => ({
  mainCard: {
    backgroundColor: "#EEEEEE",
    borderRadius: "3px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px"},

  infoCard: {
    boxShadow: "0 2px 2px 0 #b0aeae;",
    borderRadius: "2px",
  }
}));

const useSelectStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    minWidth: "100%",
  }}));

const useDateStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: "0",
    margin: "43px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    minWidth: "100%",
  }}));

const useBoxStyles = makeStyles(theme => ({
  iconView: {
    backgroundColor: "blue",
    color: "500",
    padding: 4,
    borderRadius: 4,
    //'& .MuiSvgIcon-root': {
    //  display: 'block',
    //},
    '&.web': {
      backgroundColor: "warning",
      color: "warning",
    },
    '&.phone': {
      backgroundColor: "success",
      color: "success",
    },
  },
  wordAddress: {
    //wordBreak: 'break-all',
    cursor: 'pointer',
  },
}));


function dateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}


var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function EntityProfile(props) {
  const [profileData, setProfileData] = useState({});
  //const profileData = props.location.state;
  const classes = useStyles();
  const box_classes = useBoxStyles();
  const card_classes = useCardStyles();
  const select_classes = useSelectStyles();
  const date_classes = useDateStyles();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [fullName, setFullName] = useState('');
  const [alias, setAlias] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('')
  const [role, setRole] = useState([]);
  const [bankName, setBankName] = useState('');
  const [bankBranch, setBankBranch] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [facebookHandle, setFacebookHandle] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [website, setWebsite] = useState('');
  const [deedAgreementIsChecked, setDeedAgreementIsChecked] = useState(false)

  const [TIN, setTIN] = useState([]);
  const [tradingLicense, setTradingLicense] = useState([]);
  const [resolutions, setResolutions] = useState([]);
  const [incorporation, setIncorporationCertificate] = useState([])
  let history = useHistory();

  /* ERROR HANDLERS */
  const [nameError, setNameError] = useState(false)
  const [nameHelperText, setNameHelperText] = useState('')

  const [aliasError, setAliasError] = useState(false)
  const [aliasHelperText, setAliasHelperText] = useState('')

  const [countryError, setCountryError] = useState(false)
  const [countryHelperText, setCountryHelperText] = useState('')

  const [phoneError, setPhoneError] = useState(false)
  const [phoneHelperText, setPhoneHelperText] = useState('')

  const [addressError, setAddressError] = useState(false)
  const [addressHelperText, setAddressHelperText] = useState('')

  const [roleError, setRoleError] = useState(false)
  const [roleHelperText, setRoleHelperText] = useState('')

  const [websiteError, setWebsiteError] = useState(false)
  const [websiteHelperText, setWebsiteHelperText] = useState('')

  const [bankNameError, setBankNameError] = useState(false)
  const [bankNameHelperText, setBankNameHelperText] = useState('')

  const [bankBranchError, setBankBranchError] = useState(false)
  const [bankBranchHelperText, setBankBranchHelperText] = useState('')

  const [bankAccountError, setBankAccountError] = useState(false)
  const [bankAccountHelperText, setBankAccountHelperText] = useState('')

  const [tinError, setTinError] = useState(false)
  const [tinBoxColor, setTinBoxColor] = useState('')

  const [tradingLicenseError, setTradingLicenseError] = useState(false)
  const [tradingLicenseBoxColor, setTradingLicenseBoxColor] = useState('')

  const [resolutionsError, setResolutionsError] = useState(false)
  const [resolutionsBoxColor, setResolutionsBoxColor] = useState('')

  const [incorporationError, setIncorporationError] = useState(false)
  const [incorporationBoxColor, setIncorporationBoxColor] = useState('')

  const fieldsErrorMap = {'entity_name': [setNameError, setNameHelperText],
                        'entity_alias':[setAliasError, setAliasHelperText],
                        'entity_phone': [setPhoneError, setPhoneHelperText],
                        'country': [setCountryError, setCountryHelperText],
                        'entity_website': [setWebsiteError, setWebsiteHelperText],
                        'entity_address':[setAddressError, setAddressHelperText],
                        'entity_role': [setRoleError, setRoleHelperText],
                        'entity_bank_name': [setBankNameError, setBankNameHelperText],
                        'entity_bank_branch':[setBankBranchError, setBankBranchHelperText],
                        'entity_account_number': [setBankAccountError, setBankAccountHelperText],
                        'tin': [setTinError, setTinBoxColor],
                        'trading_license': [setTradingLicenseError, setTradingLicenseBoxColor],
                        'resolutions': [setResolutionsError, setResolutionsBoxColor],
                        'incorporation': [setIncorporationError, setIncorporationBoxColor],
  }

  const attachments_fields = ['trading_license', 'incorporation', 'resolutions', 'tin']
  const handleErrors = (errorData) => {
    Object.keys(errorData).forEach(field => {
      const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
      errorSetter(true)
      if(attachments_fields.includes(field) ){
        helperTextSetter('red')
      }
      else{
        helperTextSetter(errorData[field][0].message)
      }
    });
  }

  const removeError = (field) => {
    const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
    errorSetter(false)
    if(attachments_fields.includes(field) ){
      helperTextSetter('')
    }
    //helperTextSetter('')
  }
  /* END ERROR HANDLERS */

  const api = useAxios();
  const submitHandler = async event => {
    event.preventDefault();
    setLoading(true)
    await api.post(`${BASE_URL}/updateEntityProfile/`, {
      entity_name: fullName,
      entity_alias: alias,
      entity_phone: phone,
      entity_address: address,
      entity_website: website,
      country: country,
      entity_role: role,
      entity_bank_name: bankName,
      entity_bank_branch: bankBranch,
      entity_account_name: accountName,
      entity_account_number: accountNumber,
      facebook_handle: facebookHandle,
      twitter_handle: twitterHandle,
      agree: deedAgreementIsChecked,

      tin: TIN.length ? TIN: '',
      trading_license: tradingLicense.length ? tradingLicense: '',
      resolutions: resolutions.length ? resolutions: '',
      incorporation: incorporation.length? incorporation: ''},
      ).then( response => {
        //history.push("/home/profile", { message: response.data.detail })
        window.location.replace("/home/profile")
        setLoading(false)
      }).catch( error => {
          setLoading(false)
          handleErrors(JSON.parse(error.response.data))
      })
  }

  const handleChangeMultiple = (event) => {
    const values = event.target.value;
    let new_list = []
    for (let i=0, l=values.length; i < l; i += 1){
      if(values[i]){
        new_list.push(values[i]);
      }
    }
    setRole(new_list);
  };

  const handleCheckBoxChange = (e) => {
    setDeedAgreementIsChecked(!deedAgreementIsChecked)
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Paper elevation={2} style={{"paddingBottom": "10px", "paddingTop": "5px", "borderRadius": "3px"}}>
            <CardHeader color="customBlue">
              <h5 className={classes.cardTitleWhite}>Please Complete and Submit Profile</h5>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Name of Entity"
                    id="entity_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={fullName}
                    onChange={e => {setFullName(e.target.value);}}
                    //inputProps={{
                    //  disabled: false,
                    //}}
                    error={nameError}
                    helperText={nameHelperText}
                    onFocus={e => removeError(e.target.id)}
                    
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput className={classes.formControl}
                    labelText="Business Name"
                    id="entity_alias"
                    value={alias}
                    onChange={e => setAlias(e.target.value)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    error={aliasError}
                    helperText={aliasHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="country_label">Country</InputLabel>
                    <Select
                          labelId="country_label"
                          id="country"
                          label="Country"
                          value={country}
                          onChange={e => setCountry(e.target.value)}
                          error={countryError}
                          //helperText={nameHelperText}
                          onFocus={e => removeError(e.target.id)}
                        >
                        {countryArr.map(({ label, value }) => {
                          return <MenuItem value={label} key={value}>{label}</MenuItem>
                          })
                        }

                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                        labelText="Address"
                        id="entity_address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        error={addressError}
                        helperText={addressHelperText}
                        onFocus={e => removeError(e.target.id)}
                    />
                    </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Contact"
                    id="entity_phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    error={phoneError}
                    helperText={phoneHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="role_label">Role</InputLabel>
                    <Select
                        labelId="role_label"
                        id="entity_role"
                        label="Role"
                        value={role}
                        multiple
                        onChange={handleChangeMultiple}
                        error={roleError}
                        //helperText={roleHelperText}
                        onFocus={e => removeError(e.target.id)}
                      >

                      {roles.map((role, key) => (
                        <MenuItem value={role} key={key}>{role}
                        </MenuItem>
                      ))}

                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                        labelText="Twitter Handle"
                        id="twitter_handle"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={twitterHandle}
                        onChange={e => setTwitterHandle(e.target.value)}
                    />
                    </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Facebook Handle"
                    id="facebook_handle"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={facebookHandle}
                    onChange={e => setFacebookHandle(e.target.value)}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Website"
                    id="entity_website"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={website}
                    onChange={e => setWebsite(e.target.value)}
                    error={websiteError}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="bank_name_label">Bank Name (optional)</InputLabel>
                    <Select
                        labelId="bank_name_label"
                        id="entity_bank_name"
                        label="Bank Name"
                        value={bankName}
                        onChange={e => setBankName(e.target.value)}
                        error={bankNameError}
                        //helperText={bankNameHelperText}
                        onFocus={e => removeError(e.target.id)}
                      >
                      {banksArray.map((bank, key) => {
                         return <MenuItem value={bank} key={key}>{bank}</MenuItem>
                      })}
                    </Select>                    
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Branch Name (optional)"
                    id="entity_bank_branch"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={bankBranch}
                    onChange={e => setBankBranch(e.target.value)}
                    error={bankBranchError}
                    helperText={bankBranchHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Account Name (optional)"
                    id="entity_account_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={accountName}
                    onChange={e => setAccountName(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Account Number (optional)"
                    id="entity_account_number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={accountNumber}
                    onChange={e => setAccountNumber(e.target.value)}
                    error={bankAccountError}
                    helperText={bankAccountHelperText}
                    onFocus={e => removeError(e.target.id)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Box className={box_classes.formControl}>
                      <Typography component="p" style={{"margin": "30px 10px 1px", "fontSize":"14px"}} color="error">
                        * Please read the Deed of Assignment and agree to proceed
                      </Typography>
                      <Checkbox 
                        checked={deedAgreementIsChecked}
                        id="agree"
                        onChange={e => handleCheckBoxChange(e)}
                      />
                      I Agree to this &nbsp;<DeedAttachment></DeedAttachment>
                  </Box>
                </GridItem>
              </GridContainer>

              {loading && (
                  <GridContainer>
                    <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
                      <CircularProgress size={32} color="primary"/>
                    </Backdrop>
                  </GridContainer>)}

              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <Typography component="p" style={{"margin": "20px 10px"}}>
                    Attachments(*)
                  </Typography>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <TradingDropzone 
                    required 
                    setTradingLicense={setTradingLicense}
                    onChange={e => setTradingLicense(e.target.files[0])}
                    id="trading_license"
                    boxColor={tradingLicenseBoxColor}
                    setTradingLicenseBoxColor={setTradingLicenseBoxColor}>
                  </TradingDropzone>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <TINDropzone 
                    setTIN={setTIN} 
                    onChange={e => setTIN(e.target.files[0])} 
                    id="tin"
                    boxColor={tinBoxColor}
                    setTinBoxColor={setTinBoxColor}>
                  </TINDropzone>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <Typography component="p" style={{"margin": "20px 10px"}}>
                  </Typography>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <ResolutionsDropzone 
                    setResolutions={setResolutions} 
                    onChange={e => setResolutions(e.target.files[0])} 
                    id="resolutions"
                    boxColor={resolutionsBoxColor}
                    setResolutionsBoxColor={setResolutionsBoxColor}>
                  </ResolutionsDropzone>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <IncorporationDropzone 
                    setIncorporationCertificate={setIncorporationCertificate} 
                    onChange={e => setIncorporationCertificate(e.target.files[0])} 
                    id="incorporation"
                    boxColor={incorporationBoxColor}
                    setIncorporationBoxColor={setIncorporationBoxColor}>
                  </IncorporationDropzone>
                </GridItem>

              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={submitHandler} color="info">Submit Profile</Button>
            </CardFooter>
          </Paper>
        </GridItem>
      </GridContainer>
    </div>
  );
}

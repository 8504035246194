import uuid from 'react-uuid' //uuid utility
import dayjs from 'dayjs'


const initialState = {
    userProfile: {

    },

    entityProfile: {

    },

    works: {},

    tabs: [
        {
            id: uuid(),
            title: 'Personal',
            isActive: true,
        },
        {
            id: uuid(),
            title: 'Work',
            isActive: false,
        },
        {
            id: uuid(),
            title: 'Other',
            isActive: false,
        }
    ],

    notes:[
        {
          id: uuid(),
          content: 'Untitled',
          lastEdited: dayjs(),
          isActive: true,
          tabId: ''
        }
    ]

}


export default initialState;
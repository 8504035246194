import { useState } from 'react';
import axios from "axios";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import bgImage from "assets/img/sidebar-uprs_small_no_bg.png";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { Redirect } from "react-router-dom";
import { BASE_URL } from '../../utils/useAxios';

//import { rootReducer } from '../../redux/reducers/rootReducer'
import actionCreators from '../../redux/actions/actionTypes'
import store from '../../redux/Store'

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://uprs.go.ug">
        UPRS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    //backgroundColor:
    //  theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundColor: '#e6e6e6',
    backgroundSize: 'small',
    backgroundPosition: 'center',
    //paddingLeft: '150px'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  empty: {
    backgroundColor: '#e6e6e6'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false)
  const [redirectPath, setRedirectPath ] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [tc, setTC] = useState(false);
  const [message, setMessage] = useState('')
  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //const {authData, setAuthData} = useContext(AuthContext);

  /* ERROR HANDLERS */
  const [usernameError, setUsernameError] = useState(false)
  const [usernameHelperText, setUsernameHelperText] = useState('')

  const [passwordError, setPasswordError] = useState(false)
  const [passwordHelperText, setPasswordHelperText] = useState('')


  const fieldsErrorMap = {'username': [setUsernameError, setUsernameHelperText],
                        'password': [setPasswordError, setPasswordHelperText],}
                        
  function handleLoginErrors(error){
    try {
      const errorData = JSON.parse(error.response.data)
      Object.keys(errorData).forEach(field => {
        const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
        errorSetter(true)
        helperTextSetter(errorData[field][0].message)
      });
    } 
    catch (e) {
      const errorMsg = error.response.data.detail
      setMessage(errorMsg)
      showNotification()
    }
  }

  const removeError = (field) => {
    const [errorSetter, helperTextSetter] = fieldsErrorMap[field]
    errorSetter(false)
    helperTextSetter('')
  }
  /* END ERROR HANDLERS */

  const submitHandler = event => {
    event.preventDefault();
    setLoading(true)
    axios.post(`${BASE_URL}/login/`,
    //axios.post('http://' + require("ip").address() + ':9000/members/login/',
      {
        username: username,
        password: password},
      //{
      //  withCredentials:true},
      {
      headers: {
          //'Authorization': `Token ${localStorage.getItem('token')}`
      }}).then( response => {
        localStorage.setItem("userToken", response.data.token)
        localStorage.setItem("isAuthenticated", true)
        store.dispatch({type: actionCreators.profile_refreshed(), payload: response.data.profile})
        setRedirectPath(store.getState().userProfile.submitted ? "/home/profile" : "/home/complete_profile")
        setLoading(false)
        setRedirect(true)
      }).catch( error => {
        setLoading(false)
        handleLoginErrors(error)
      })
  }

  if (redirect){
    return <Redirect to={redirectPath} />
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={5} md={7} className={classes.image}/>
      <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={e => setUsername(e.target.value)}
              error={usernameError}
              helperText={usernameHelperText}
              onInput={e => removeError(e.target.id)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              autoFocus
              onChange={e => setPassword(e.target.value)}
              error={passwordError}
              helperText={passwordHelperText}
              onInput={e => removeError(e.target.id)}
              InputProps={{ endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }}
            />
            {/*
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />*/}
            <Button
              onClick={submitHandler}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Continue
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/password_reset" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Register here"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          {loading && (
            <Grid container>
              <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
                <CircularProgress size={32} color="danger"/>
              </Backdrop>
            </Grid>)}
        </div>
      </Grid>
      <Grid item xs={false} sm={1} md={1} className={classes.empty}>
        <Snackbar
            place="tc"
            color="danger"
            icon={AddAlert}
            message={message}
            open={tc}
            closeNotification={() => setTC(false)}
            close
          />
      </Grid>  
    </Grid>
  );
}


/*
import React from 'react';
import {
  Checkbox,
  Grid,
  TextField,
  FormControlLabel,
  Paper,
  Button
} from '@material-ui/core';
const LoginPage = () => {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div style={{ padding: 30 }}>
      <Paper>
        <Grid
          container
          spacing={3}
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
        >
          <Grid item xs={12}>
            <TextField label="Username"></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Password" type={'password'}></TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  label={'Keep me logged in'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Keep me logged in"
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth> Login </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default LoginPage;
*/
import React from 'react'
import {useDropzone} from 'react-dropzone'
import { Box, List, ListItem, Typography } from '@material-ui/core'
import {useDzStyles, convertFileToBase } from './DropzoneArea'


const MyDropzone = (props) => {
    const { setPassportPhoto, setPassportPhotoBoxColor, boxColor } = props
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    let attachment = acceptedFiles[0];
    convertFileToBase(attachment, response => {
        let values = []
        values.push({
            file: response,
            title: attachment.name,
            size: parseFloat((parseFloat(attachment.size) / 1024).toFixed(2)),
            details: 'Passport Photo',
            type: attachment.type
        })
        setPassportPhoto(values);
    })

    //setPassportPhoto(acceptedFiles[0]?.path)
    const files = acceptedFiles.map(file => (
        <ListItem key={file.path}>
        {file.path}
        </ListItem>
    ));
    const dz_classes = useDzStyles();

    return (
        <Box className={dz_classes.formControl} color={boxColor} onClick={e => setPassportPhotoBoxColor('')}>
            <Box {...getRootProps()}>
                <input className={dz_classes.formControl} {...getInputProps()} />
                <Typography>Attach Passport Photo (click to select file)</Typography>
            </Box>
            <aside>
                <List>{files}</List>
            </aside>
        </Box>
    );
};

export default MyDropzone;

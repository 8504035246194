/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// I18 Compnents for Translation
// import { I18nextProvider } from 'react-i18next';
// import i18n from './i18n';

// core components
import Home from "layouts/Admin.js";
//import RTL from "layouts/RTL.js";
import Register from "views/Account/Register"
import Login from "views/Account/Login"
import ResetPassword from "views/Account/ResetPassword"
import ResetPasswordConfirm from "views/Account/ResetPasswordConfirm"
import ResetPasswordSuccess from "views/Account/PasswordResetSuccessful"
import ResetPasswordEmailSent from "views/Account/PasswordResetEmailSent"

//import { AuthProvider } from "./context/AuthContext";
import {AuthProvider} from "./hooks/useAuth";
import { Provider } from 'react-redux'
import store from './redux/Store.js'

import "assets/css/material-dashboard-react.css?v=1.10.0";
const user = localStorage.getItem('userToken');

// // Render for I18 next (Translation)
// ReactDOM.render(
//   <I18nextProvider i18n={i18n}>
//     <App />
//   </I18nextProvider>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}> {/*user={user}*/}
      <Switch>
        <Route path="/home" component={Home} />
        {/*<Route path="/rtl" component={RTL} />
        <Route path="/home/works/addwork" component={AddWork} />*/}
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/password_reset" component={ResetPassword} />
        <Route path="/password_reset_email_sent" component={ResetPasswordEmailSent} />
        <Route path="/password_reset_confirm" component={ResetPasswordConfirm} />
        <Route path="/reset_successful" component={ResetPasswordSuccess} />
        <Redirect from="/" to="/register" />

        {/*<Redirect from="/" to="/members/register" />
        <Redirect from="/" to="/admin/dashboard" />*/}
      </Switch>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

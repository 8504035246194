import { makeStyles } from "@material-ui/core/styles";
import useAxios from "../../utils/useAxios";
import { BASE_URL } from '../../utils/useAxios';

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import CardFooter from "components/Card/CardFooter.js";
import AgreementDropzone from "components/Dropzone/AgreementDropzone"
import ArtworkDropzone from "components/Dropzone/ArtworkDropzone"

//import Dropzone from 'react-dropzone'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
//import DateFnsUtils from '@date-io/date-fns'
import MomentUtils from '@date-io/moment';
import {Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import store from '../../redux/Store'
import actionCreators from '../../redux/actions/actionTypes'
import genreArray from "./Genres";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  cardCategoryWhite: {
    //color: "rgba(255,255,255,.62)",
    color: "#FFFFFF",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    paddingBottom: "7px",
    textDecoration: "none",
    marginTop: "15px",
    borderBottom: "1px solid #c7c7c7"
  },
  
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },

};
const useStyles = makeStyles(styles);

const useCardStyles = makeStyles(theme => ({
  mainCard: {
    backgroundColor: "#EEEEEE",
    borderRadius: "3px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px"},

  infoCard: {
    boxShadow: "0 2px 2px 0 #b0aeae;",
    borderRadius: "2px",
  }
}));

const useSelectStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    minWidth: "100%",
  }}));

const useDateStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: "0",
    margin: "43px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    minWidth: "100%",
  }}));

const useBoxStyles = makeStyles(theme => ({
  iconView: {
    backgroundColor: "blue",
    color: "500",
    padding: 4,
    borderRadius: 4,
    //'& .MuiSvgIcon-root': {
    //  display: 'block',
    //},
    '&.web': {
      backgroundColor: "warning",
      color: "warning",
    },
    '&.phone': {
      backgroundColor: "success",
      color: "success",
    },
  },
  wordAddress: {
    //wordBreak: 'break-all',
    cursor: 'pointer',
  },
}));

function dateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

function getStartingDate(){
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    //return date.toISOString().slice(0,10)
    return dateToYMD(date)
  }

export default function AddWork(props) {
  const classes = useStyles();
  const box_classes = useBoxStyles();
  const card_classes = useCardStyles();
  const select_classes = useSelectStyles();
  const date_classes = useDateStyles();
  const [genreList, setGenreList] = useState(genreArray)

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  let history = useHistory();
  const [title, setTitle] = useState('');
  const [genre, setGenre] = useState('');
  const [publisher, setPublisher] = useState('');
  const [composer, setComposer] = useState('');
  const [producer, setProducer] = useState('')
  const [performer, setPerformer] = useState('');
  const [publisher_percent, setPublisherPercent] = useState(0);
  const [composer_percent, setComposerPercent] = useState(0);
  const [producer_percent, setProducerPercent] = useState(0);
  const [performer_percent, setPerformerPercent] = useState(0);
  const [duration, setDuration] = useState('');
  const [dateReleased, setDate] = useState(getStartingDate());
  const [youtube_link, setYoutubeLink] = useState('');
  const [other_link, setOtherLink] = useState('');

  const [workAgreement, setWorkAgreement] = useState(['']);
  const [artwork, setArtwork] = useState(['']);


  /* ERROR HANDLERS */
  const [titleError, setTitleError] = useState(false)
  const [durationError, setDurationError] = useState(false)
  const [dateError, setDateError] = useState(false)

  const fieldsErrorMap = {'title': setTitleError,
                        'duration':setDurationError,
                        'date': setDateError,
  }

  const handleErrors = (errorData) => {
    Object.keys(errorData).forEach(field => {
      const errorSetter = fieldsErrorMap[field]
      errorSetter(true)
    });
  }

  const removeError = (field) => {
    const errorSetter = fieldsErrorMap[field]
    errorSetter(false)
  }
  
  /* END ERROR HANDLERS */

  const api = useAxios();

  const submitHandler = async event => {
    event.preventDefault();
    setLoading(true)
    await api.post(`${BASE_URL}/addWork/`, {
      title: title,
      genre: genre ? genre : 'Jazz',
      publisher_percentage: publisher_percent,
      producer_percentage: producer_percent,
      composer_percentage: composer_percent,
      performer_percentage: performer_percent,
      performer: performer,
      composer: composer,
      publisher: publisher,
      producer: producer,
      duration: duration,
      date: dateReleased,
      agree: true,
      youtube_link: youtube_link,
      other_link: other_link,
      work_agreement: workAgreement ? workAgreement : '',
      artwork: artwork ? artwork : ''},
      ).then( response => {
          store.dispatch({type: actionCreators.works_refreshed(), payload: response.data})
          setLoading(false)
          history.push("/home/works", { message: response.data.detail })
      }).catch( error => {
        setLoading(false)
        handleErrors(JSON.parse(error.response.data))
      })
  }

  const handleDateChange = (date) => {
    const dob = date._d;
    setDate(dateToYMD(dob))
  }

  useEffect(async () => {
    await api.get(`${BASE_URL}/genres/`,
    ).then((response) => {
      setGenreList(response.data.genres);
      })
  }, []);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <Paper elevation={2} style={{"paddingBottom": "10px", "paddingTop": "5px", "borderRadius": "3px"}}>
              <CardHeader color="customBlue">
                <h5 className={classes.cardTitleWhite}>Add work item</h5>
              </CardHeader>
              <CardBody>
                <form id="workform">
                  <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                              labelText="Song Title"
                              id="title"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                              error={titleError}
                              onFocus={e => removeError(e.target.id)}
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                          <CustomInput className={classes.formControl}
                              labelText="Duration (in minutes)"
                              id="duration"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={duration}
                              onChange={e => setDuration(e.target.value)}
                              error={durationError}
                              onFocus={e => removeError(e.target.id)}
                          />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={3}>
                          <FormControl className={select_classes.formControl}>
                              <InputLabel id="genre_label">Genre</InputLabel>
                              <Select
                                  labelId="genre_label"
                                  id="genre"
                                  label="Genre"
                                  value={genre}
                                  onChange={e => setGenre(e.target.value)}
                                  >
                                  {genreList.map((prop, key) => {
                                      return <MenuItem value={prop.name} key={key}>{prop.name}</MenuItem>
                                  })}
                              </Select>
                          </FormControl>
                      </GridItem>
                  
                      <GridItem xs={12} sm={12} md={3}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                              <DatePicker 
                                className={select_classes.formControl} /*date_classes.formControl*/
                                value={dateReleased} format="yyyy-MMMM-DD" 
                                onChange={e => handleDateChange(e)} 
                                disableFuture
                                label="Release Date"
                              />
                          </MuiPickersUtilsProvider>
                        {/*
                        <FormControl className={select_classes.formControl}>
                          <TextField
                            id="date"
                            label="Date Released"
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                        */}
                      </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                          labelText="Producer"
                          id="producer"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={producer}
                          onChange={e => setProducer(e.target.value)}
                      />
                      </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                          labelText="Producer Percentage"
                          id="producer_percent"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={producer_percent}
                          onChange={e => setProducerPercent(e.target.value)}
                      />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                          labelText="Performer"
                          id="performer"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={performer}
                          onChange={e => setPerformer(e.target.value)}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                            labelText="Performer Percentage"
                            id="performer_percent"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={performer_percent}
                            onChange={e => setPerformerPercent(e.target.value)}
                        />
                      </GridItem>
                </GridContainer>
                <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Composer"
                            id="composer"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={composer}
                            onChange={e => setComposer(e.target.value)}
                          />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Composer Percentage"
                            id="composer_percent"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={composer_percent}
                            onChange={e => setComposerPercent(e.target.value)}
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Publisher"
                            id="publisher"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={publisher}
                            onChange={e => setPublisher(e.target.value)}
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Publisher Percentage"
                            id="publisher_percent"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={publisher_percent}
                            onChange={e => setPublisherPercent(e.target.value)}
                          />
                      </GridItem>
                  </GridContainer>

                  <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="YouTube Link"
                            id="youtube_link"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={youtube_link}
                            onChange={e => setYoutubeLink(e.target.value)}
                          />
                          </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Other Link"
                            id="other_link"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={other_link}
                            onChange={e => setOtherLink(e.target.value)}
                          />
                      </GridItem>
                  </GridContainer>

                  {loading && (
                  <GridContainer>
                    <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
                      <CircularProgress size={32} color="danger"/>
                    </Backdrop>
                  </GridContainer>)}

                  <GridContainer >
                      <GridItem xs={12} sm={12} md={12}>
                          <Typography component="p" style={{"margin": "20px 10px"}}>
                              Attachments (optional)
                          </Typography>
                      </GridItem>

                      <GridItem xs={11} sm={11} md={5} style={{"marginBottom": "10px"}}>
                          <AgreementDropzone required setWorkAgreement={setWorkAgreement} onChange={e => setWorkAgreement(e.target.files[0])}>
                          </AgreementDropzone>
                      </GridItem>
                      <GridItem xs={1} sm={1} md={2}>
                      </GridItem>
                      <GridItem xs={11} sm={11} md={5}>
                          <ArtworkDropzone required setArtwork={setArtwork} onChange={e => setArtwork(e.target.files[0])}>
                          </ArtworkDropzone>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                          <Typography component="p" style={{"margin": "20px 10px"}}>
                          </Typography>
                      </GridItem>
                  </GridContainer>
                  </form>
              </CardBody>
              <CardFooter>
                <Button onClick={submitHandler} color="info">Submit Work</Button>
              </CardFooter>
            </Paper>
          
        </GridItem>
      </GridContainer>
    </div>
  );
}


import EditIndividualProfile from './EditUserProfile'
import EditEntityProfile from './EditEntityProfile'
//import store from '../../redux/Store'

const Profile = () => {
    const data = JSON.parse(localStorage.getItem('profileData'))
    //const profileData = store.getState().userProfile
    if (data.member_type === 'individual'){
        return <EditIndividualProfile profileData={data}/>
    }
    return <EditEntityProfile profileData={data}/>
}

export default Profile
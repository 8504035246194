import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

import {useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  let history = useHistory();
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor, setWorkID} = props;

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { field: 'title', headerName: 'Title', width: 250 },
    { field: 'date_uploaded', headerName: 'Uploaded', width: 250 },
    { field: 'genre', headerName: 'Genre.', width: 250 },
    { field: 'work_num', headerName: 'Work No.', width: 250 },
  ];
  
  //const handleOnclick = (event, key) => {
  //  setWorkID(key)
  //}
  
  //const handleOnclick = (event, key) => {
  //  localStorage.setItem('current_work_id', key)
  //  history.push(`/home/work_details`)
  //}

  const handleOnclick = (event) => {
    localStorage.setItem('current_work_id', event.row.work_num)
    history.push(`/home/work_details`)
  }


  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid 
        rows={tableData}
        onRowClick={e => handleOnclick(e)}
        columns={columns}
        pageSize={5}
        //checkboxSelection
        disableSelectionOnClick
      />
    </div>
  //)}*/
  /*
  return (
    <div className={classes.tableResponsive}>
      <TableContainer>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow key={prop.work_num} className={classes.tableBodyRow} onClick={event => handleOnclick(event, prop.work_num)} style={{'cursor':'pointer'}}>
                  <TableCell className={classes.tableCell}>
                      {prop.title}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                      {prop.work_num}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                      {prop.genre}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                      {prop.date_uploaded}
                  </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={<span>Rows:</span>}
                labelDisplayedRows={({ page }) => {
                  return `Page: ${page}`;
                }}
                backIconButtonProps={{
                  color: "secondary"
                }}
                nextIconButtonProps={{ color: "secondary" }}
                SelectProps={{
                  inputProps: {
                    "aria-label": "page number"
                  }
                }}
                showFirstButton={true}
                showLastButton={true}
                //ActionsComponent={TablePaginationActions}
                //component={Box}
                //sx and classes prop discussed in styling section
              />
            </TableRow>
          </TableFooter>
      </Table>
      </TableContainer>
      */
     /*
      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>*/
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "info",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
    "customBlue"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  //tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  tableData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

import WorksList from "../views/Works/WorksList";
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        works: state.works
    };
}

const mapDispatchToProps = (dispatch) => ({
    onLoadWorksData: () => dispatch()
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorksList)
import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Cookies from 'js-cookie'

//export const BASE_URL = "http://127.0.0.1:9000/members";
//export const BASE_URL = "http://community.uprs.go.ug/members";
export const BASE_URL = "https://membership.uprs.go.ug/members";

//export const MEDIA_BASE_URL = "http://127.0.0.1:9000"
//export const MEDIA_BASE_URL = "http://community.uprs.go.ug";
export const MEDIA_BASE_URL = "https://membership.uprs.go.ug";


const useAxios = () => {
  //const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);
  const accessToken = localStorage.getItem('userToken')
  const refreshToken = Cookies.get('refresh_token')
  //console.log("REFRESHER IS", refreshToken, "ACCESS IS", accessToken)

  const axiosInstance = axios.create({
    BASE_URL,
    headers: { Authorization: `Bearer ${accessToken}` }
    //headers: { Authorization: `Bearer ${authTokens?.access}` }
    
  });

  axiosInstance.interceptors.request.use(async req => {
    //const user = jwt_decode(authTokens.access);
    const user = jwt_decode(accessToken);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    //for now, return the login page instead of token refresh
    localStorage.clear();
    window.location.replace("/login")

    //TODO: this will be for token refresh functionality
    const response = await axios.post(`${BASE_URL}/token/refresh/`, {
      //refresh: authTokens.refresh
      refresh: refreshToken
    });

    //localStorage.setItem("authTokens", JSON.stringify(response.data));
    localStorage.setItem("userToken", response.data.token);

    //setAuthTokens(response.data);
    //setUser(jwt_decode(response.data.access));

    //req.headers.Authorization = `Bearer ${response.data.access}`;
    req.headers.Authorization = `Bearer ${response.data.token}`;
    return req;
  });

  return axiosInstance;
};

export default useAxios;

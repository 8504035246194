import React from 'react';
import { Box } from '@material-ui/core';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IDIcon from '@material-ui/icons/Fingerprint';
import SendMobileIcon from '@material-ui/icons/MobileFriendly';
import blue from '@material-ui/core/colors/blue';
import { geValidUrl } from '../../../utils/commonHelper';

const useStyles = makeStyles(theme => ({
  iconView: {
    //backgroundColor: alpha(blue['500'], 0.1),
    color: blue['500'],
    //backgroundColor: alpha(theme.palette.warning.main, 0.25),
    //color: '#5D4037',
    padding: 8,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
    '&.web': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
    },
    '&.phone': {
      backgroundColor: alpha(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark,
    },
  },
  wordAddress: {
    wordBreak: 'break-all',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '1px',
    marginTop: '1px'
  },
  spacer:{
    marginBottom: '18px'
  }
}));

const Billing = ({ userDetail }) => {
  //const { profile } = userDetail
  const { billing, address, twitter_handle, facebook_handle, phone } = userDetail;
  const {bank_name, bank_branch, account_name, account_number, mobile_money_number, registered_name } = billing[0]
  const classes = useStyles();
  return (
    <CmtCard elevation={2}>
      <CmtCardHeader title="Billing" />
      <CmtCardContent>
        {bank_name && (
        <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <AccountBalanceIcon/>
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12}>
              Bank Name
            </Box>
            <Box component="p" className={classes.wordAddress} color="text.primary">
                {bank_name}
            </Box>
          </Box>
        </Box>)}

        {bank_branch && (
        <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <LocationOnIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12}>
              Bank Branch
            </Box>
            <Box component="p" className={classes.wordAddress} color="text.primary">
                {bank_branch}
            </Box>
          </Box>
        </Box>)}

        {account_name && (
        <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <AccountCircleIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12}>
              Account Name
            </Box>
            <Box component="p" className={classes.wordAddress} color="text.primary">
                {account_name}
            </Box>
          </Box>
        </Box>)}

        {account_number && (
        <Box display="flex" className={classes.spacer} alignItems="center">
          <Box className={classes.iconView}>
            <IDIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12}>
              Account Number
            </Box>
            <Box component="p" className={classes.wordAddress} color="text.primary">
              {account_number}
            </Box>
          </Box>
        </Box>)}

        {mobile_money_number && (
        <Box display="flex" className={classes.spacer} alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <SendMobileIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12}>
              Mobile Money Number
            </Box>
            <Box component="p" className={classes.wordAddress} color="text.primary">
                {mobile_money_number}
            </Box>
          </Box>
        </Box>)}

        {registered_name && (
        <Box display="flex" className={classes.spacer} alignItems="center">
          <Box className={classes.iconView}>
            <AccountCircleIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12}>
              Registered Name
            </Box>
            <Box component="p" className={classes.wordAddress} color="text.primary">
              {registered_name}
            </Box>
          </Box>
        </Box>)}
      </CmtCardContent>
    </CmtCard>
  );
};

export default Billing;

Billing.prototype = {
  userDetail: PropTypes.object.isRequired,
};
